import React, { useRef, useState } from 'react'
import Swiper from 'react-id-swiper'
import 'swiper/swiper.min.css'
import { Autoplay, Pagination, Navigation } from "swiper";

const SliderOne = () => {
  const ref = useRef({
    slidesPerView: 1,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    loop: true
  });

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev();
    }
  };

  return (
    <div className='banner-wrapper'>
      <section className='banner-one banner-carousel__one no-dots'>
        <Swiper
          ref={ref}
          loop={true}          
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
        >
          <div className='banner-three__slide banner-one__slide-three'>
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12 centerBtn'>
                  <h3 className='banner-one__title size banner-one__light-color' style={{color:"white"}}>
                    Fostering The Seed of Curiosity
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className='banner-one__slide size banner-one__slide-one'>
            <div className='container'>
              <div className='row no-gutters'>
                <div className='col-xl-12 centerBtn'>
                  <h3 className='banner-one__title size banner-one__light-color'style={{color:"white"}}>
                    Imparting Education That Speaks Volume
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className='banner-two__slide banner-one__slide-one'>
            <div className='container'>
              <div className='row no-gutters centerBtn'>
                <div className='col-xl-12'>
                  <h3 className='banner-one__title size banner-one__light-color' style={{color:"white"}}>
                    Nurturing Tomorrow’s Global Citizens
                  </h3>
                </div>
              </div>
            </div>
          </div>
       </Swiper>
      </section>
      <div className='banner-carousel-btn'>
        <div
          onClick={goPrev}
          onKeyUp={goPrev}
          tabIndex={0}
          role='button'
          className='banner-carousel-btn__left-btn banner-arrow swiper-button-next'
        >
          <i className='kipso-icon-left-arrow'></i>
        </div>
        <div
          onClick={goNext}
          onKeyDown={goNext}
          role='button'
          tabIndex={0}
          className='banner-carousel-btn__right-btn banner-arrow swiper-button-prev'
        >
          <i className='kipso-icon-right-arrow'></i>
        </div>
      </div>
      {/* <div className="banner-one__cta">
        <div className="banner-one__cta-icon">
          <i className="kipso-icon-black-graduation-cap-tool-of-university-student-for-head"></i>
        </div>
        <div className="banner-one__cta-title">
          <h3 className="banner-one__cta-text">
            <a href="#none">Read how we encourage our students to learn</a>
          </h3>
        </div>
        <div className="banner-one__cta-link">
          <a href="#none">
            <i className="kipso-icon-right-arrow"></i>
          </a>
        </div>
      </div> */}
    </div >
  )
}
export default SliderOne
